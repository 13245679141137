import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getCompanyName } from '../../util/getCompanyName';
import { companiesNames } from '../../constants/companies';

import LbLogo from '../../assets/images/LB-Logo.png';
import communikeyLogo from '../../assets/images/Communikey-Logo.svg';

import './styles.less';

const companyName = getCompanyName();

const selectLogo = () => {
  if (companyName === companiesNames.COMMUNIKEY) {
    return communikeyLogo;
  }

  return LbLogo;
};

const AuthLayout = ({ children }) => {
  return (
    <div className="auth-layout">
      <div className="sign-in-container">
        <div className="sigh-in-section">{children}</div>
        <div className="logo-section">
          <Link className="logo" to="/" title={companyName}>
            <img
              src={selectLogo()}
              alt={companyName}
              title={companyName}
              className="logo-img"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
