import { observable, decorate, action } from 'mobx';
import callStates from '../util/callStates';

const ACTIONS = [
  {
    key: 'DISABLE_VISITOR',
    activate: 'action_disable_visitors',
    deactivate: 'action_enable_visitors',
  },
  {
    key: 'LOCKDOWN',
    activate: 'action_door_lockdown',
    deactivate: 'action_door_unlockdown',
  },
  {
    key: 'UNLOCK',
    activate: 'action_door_unlock',
    deactivate: 'action_door_lock',
  },
  {
    key: 'OPEN_DOOR',
    activate: 'action_door_open',
    deactivate: '',
  },
];
class ControlTableStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  tableData = [];

  isTimerModalActive = false;

  idsVuInProcess = [];

  isLoading = true;

  resetTableStore = () => {
    this.tableData = [];
    this.isTimerModalActive = false;
    this.idsVuInProcess = [];
    this.isLoading = true;
  };

  setCTData = (data) => {
    const sortedData = data.sort((a, b) => {
      if (a.status === 'connected' && b.status === 'not_connected') {
        return -1;
      }

      if (a.status === 'not_connected' && b.status === 'connected') {
        return 1;
      }

      return 0;
    });
    this.tableData = sortedData;
    this.isLoading = false;
  };

  updateData = (responseObject) => {
    const preparedTableData = this.tableData.map((visitorUnit) => {
      if (
        this.rootStore.tokBoxStore.currentConnection &&
        visitorUnit.device_id ===
          this.rootStore.tokBoxStore.currentConnection.currentVuId &&
        this.rootStore.tokBoxStore.currentConnection.callState ===
          callStates.DISCONNECTING
      ) {
        this.rootStore.tokBoxStore.clearSession();
      }

      if (visitorUnit.device_id === responseObject.device_id) {
        this.idsVuInProcess = this.idsVuInProcess.filter(
          (id) => id !== responseObject.device_id
        );
        return responseObject;
      }

      return visitorUnit;
    });
    this.isLoading = false;
    this.tableData = preparedTableData;
  };

  subscribe = () => {
    this.rootStore.socket.subscribe('device_list', ({ data }) => {
      this.setCTData(data);
    });
    this.rootStore.socket.subscribe('status_update', ({ data }) => {
      this.updateData(data);
    });
  };

  getDevices = () => {
    this.isLoading = true;
    this.rootStore.socket.send({ activity: 'action_get_devices' });
  };

  setTimer = (id, duration) => {
    this.rootStore.socket.send({
      activity: 'action_set_timer',
      visitor_unit: id,
      minutes: duration,
    });
  };

  triggerAction = (actionKey, id, currentState) => {
    this.idsVuInProcess.push(id);
    const status = currentState === 'available' ? 'activate' : 'deactivate';
    const currentAction = ACTIONS.find((el) => el.key === actionKey)[status];

    if (currentAction) {
      this.rootStore.socket.send({
        activity: currentAction,
        visitor_unit: id,
      });
    }
  };
}

export default decorate(ControlTableStore, {
  tableData: observable,
  idsVuInProcess: observable,
  isTimerModalActive: observable,
  setCTData: action,
  updateData: action,
  subscribe: action,
  getDevices: action,
  setTimer: action,
  triggerAction: action,
});
