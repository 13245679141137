import React from 'react';
import { observer } from 'mobx-react';
import { Empty } from 'antd';
import { nanoid } from 'nanoid';
import { FormattedMessage } from 'react-intl';

import { useRootStore } from '../../store/RootStateContext';
import LogItem from '../LogItem';

import './styles.less';

const LogsSection = () => {
  const { logsStore, companiesStore } = useRootStore();

  const { currentCompanyId } = companiesStore;

  const logs = logsStore.logs[currentCompanyId] || [];

  const getId = () => nanoid();

  const renderLogs = () => {
    if (logs.length) {
      return logs.map((item) => <LogItem key={getId()} item={item} isLog />);
    }

    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<FormattedMessage id="logsSection.noData" />}
      />
    );
  };

  return (
    <div className="logs-list-wrapper">
      <div className="list-header">
        <div className="list-header-title">
          <FormattedMessage id="logsSection.realTimeLogging" />
        </div>
        <button
          type="button"
          className="list-header-clear-btn"
          onClick={logsStore.clearLogs}
          disabled={!logs.length}
        >
          <span className="list-header-clear-btn-title">
            <FormattedMessage id="logsSection.acknowledgeAll" />
          </span>
        </button>
      </div>
      {renderLogs()}
    </div>
  );
};

export default observer(LogsSection);
