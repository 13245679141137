import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'antd';
import s from './ConfirmForm.module.css';

import LangSwitch from '../LangSwitch/LangSwitch';

import { useRootStore } from '../../store/RootStateContext';
import CustomForm from '../CustomForm';
import { comparePasswords, validatePassword } from '../../services/validators';

import './styles.less';

const ConfirmForm = () => {
  const history = useHistory();
  const params = useParams(history.location.pathname);
  const { authStore } = useRootStore();

  const intl = useIntl();

  const CONFIRMATION_PASSWORD_FIELDS = [
    {
      name: 'newPassword1',
      rules: [
        {
          required: true,
          message: <FormattedMessage id="confirmForm.msgEmptyPass" />,
        },
        () => validatePassword(),
      ],
      hasFeedback: true,
      placeholder: intl.formatMessage({ id: 'confirmForm.newPassword' }),
      prefix: <LockOutlined className="site-form-item-icon" />,
    },
    {
      name: 'newPassword2',
      dependencies: ['newPassword1'],
      rules: [
        {
          required: true,
          message: <FormattedMessage id="confirmForm.msgEmptyPassConfirm" />,
        },
        ({ getFieldValue }) => comparePasswords(getFieldValue, 'newPassword1'),
      ],
      hasFeedback: true,
      placeholder: intl.formatMessage({ id: 'confirmForm.repeatNewPassword' }),
      prefix: <LockOutlined className="site-form-item-icon" />,
    },
  ];

  const onFinish = ({ newPassword1, newPassword2 }) => {
    if (params.resetToken) {
      const { resetToken } = params;
      authStore.confirmRecoveryPassword(resetToken, newPassword1, newPassword2);
    }

    if (params.uid) {
      const { uid, token } = params;
      authStore.confirmPassword(uid, token, newPassword1, newPassword2);
    }
  };

  useEffect(() => {
    return () => {
      authStore.passwordConfirmationMsg = '';
    };
  }, [authStore]);

  const goToLoginForm = () => {
    history.push('/signin');
  };

  return (
    <>
      <div className={s.langSwitch}>
        <LangSwitch />
      </div>
      <div className="confirmation-title">
        <FormattedMessage id="confirmForm.confirmScreen" />
      </div>
      {!authStore.passwordConfirmationMsg ? (
        <CustomForm
          formName="confirmation-form"
          formClassName="confirmation-form"
          fields={CONFIRMATION_PASSWORD_FIELDS}
          onSubmit={onFinish}
          inputDisabled={false}
          inputType="password"
          title={<FormattedMessage id="confirmForm.confirmPassword" />}
          size="large"
        />
      ) : (
        <div className="confirmationMsg">
          <p>{authStore.passwordConfirmationMsg}</p>
          <Button type="primary" onClick={goToLoginForm}>
            <FormattedMessage id="signInForm.logInScreen" />
          </Button>
        </div>
      )}
    </>
  );
};

export default observer(ConfirmForm);
