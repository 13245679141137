import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from '../Modal';
import { useRootStore } from '../../store/RootStateContext';

export default function PrimarySecurityShiftPopup({
  title,
  body,
  okBtnTxt,
  cancelBtnTxt,
  onDecline,
}) {
  const { authStore } = useRootStore();

  const onConfirm = () => {
    authStore.isPrimaryShift(true);
    authStore.isOpenPopupPrimarySecurity = false;
  };

  const onDeclineDefault = () => {
    authStore.isUserDecision = true;
    authStore.isPrimaryShift(false);
  };

  return (
    <Modal
      title={title}
      okBtnTxt={okBtnTxt || <FormattedMessage id="modal.yes" />}
      onOk={onConfirm}
      cancelBtnTxt={cancelBtnTxt || <FormattedMessage id="modal.no" />}
      onCancel={onDecline || onDeclineDefault}
    >
      {body}
    </Modal>
  );
}

PrimarySecurityShiftPopup.defaultProps = {
  okBtnTxt: '',
  cancelBtnTxt: '',
  onDecline: null,
};

PrimarySecurityShiftPopup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  body: PropTypes.any.isRequired,
  okBtnTxt: PropTypes.string,
  cancelBtnTxt: PropTypes.string,
  onDecline: PropTypes.func,
};
