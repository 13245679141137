import React from 'react';
import { Layout } from 'antd';

import { ProfileSection } from '../../components';
import './styles.less';

const ProfilePage = () => {
  const { Content } = Layout;

  return (
    <Content className="profile-content-wrapper">
      <div className="profile-layout-wrapper">
        <ProfileSection />
      </div>
    </Content>
  );
};

export default ProfilePage;
