import {
  DashboardPage,
  SettingsPage,
  ProfilePage,
  SignInPage,
} from './containers';

const authRoutes = [
  {
    path: [
      '/signin',
      '/password/setup/:uid/:token',
      '/password/recovery',
      '/accounts/password/reset/key/:resetToken',
      '/errorPage',
    ],
    exact: true,
    Component: SignInPage,
  },
];

const routes = [
  {
    path: '/app/dashboard',
    exact: true,
    Component: DashboardPage,
  },
  {
    path: '/app/settings',
    exact: true,
    Component: SettingsPage,
  },
  {
    path: '/app/profile',
    exact: true,
    Component: ProfilePage,
  },
];

const routesWithoutSettings = [
  {
    path: '/app/dashboard',
    exact: true,
    Component: DashboardPage,
  },
  {
    path: '/app/profile',
    exact: true,
    Component: ProfilePage,
  },
];

export { authRoutes, routes, routesWithoutSettings };
