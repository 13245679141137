import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { useRootStore } from '../../store/RootStateContext';
import { comparePasswords, validatePassword } from '../../services/validators';
import Spinner from '../Spinner';
import CustomForm from '../CustomForm';

import './styles.less';

const PASSWORD_FIELDS = [
  {
    name: 'oldPassword',
    label: <FormattedMessage id="profileSection.oldPassword" />,
  },
  {
    name: 'newPassword1',
    label: <FormattedMessage id="profileSection.newPassword" />,
    rules: [
      {
        required: true,
        message: <FormattedMessage id="profileSection.msgEmptyPass" />,
      },
      () => validatePassword(),
    ],
  },
  {
    name: 'newPassword2',
    label: <FormattedMessage id="profileSection.newPassConfirmation" />,
    dependencies: ['newPassword1'],
    rules: [
      {
        required: true,
        message: <FormattedMessage id="profileSection.msgEmptyConfirmPass" />,
      },
      ({ getFieldValue }) => comparePasswords(getFieldValue, 'newPassword1'),
    ],
  },
];

const ProfileSection = () => {
  const { authStore } = useRootStore();

  const handlePasswordChange = ({
    oldPassword,
    newPassword1,
    newPassword2,
  }) => {
    authStore.changePassword(oldPassword, newPassword1, newPassword2);
  };

  return authStore.rootStore.isLoading ? (
    <div className="profile-spinner-wrapper">
      <Spinner />
    </div>
  ) : (
    <div className="section-wrapper">
      <div className="header">
        <FormattedMessage id="profileSection.profile" />
      </div>
      <CustomForm
        formName="profile"
        fields={authStore.profileInfo}
        formClassName="profile-form-wrapper"
        inputDisabled
        inputType="input"
      />
      <CustomForm
        formName="activity"
        formClassName="profile-form-wrapper"
        fields={authStore.profileActivityInfo}
        inputDisabled
        inputType="input"
      />
      <CustomForm
        formName="passwordChange"
        formClassName="profile-password-form-wrapper"
        fields={PASSWORD_FIELDS}
        onSubmit={handlePasswordChange}
        title={<FormattedMessage id="profileSection.changePass" />}
        inputDisabled={false}
        inputType="password"
      />
    </div>
  );
};

export default observer(ProfileSection);
