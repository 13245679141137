import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  SignInForm,
  ConfirmForm,
  PasswordRecoveryForm,
} from '../../components';

import './styles.less';

const SignInPage = () => {
  const { location } = useHistory();

  const renderForm = () => {
    const { pathname } = location;

    if (pathname.indexOf('/password/setup/') > -1) {
      return <ConfirmForm />;
    }

    if (pathname.indexOf('/accounts/password/reset/key') > -1) {
      return <ConfirmForm />;
    }

    if (pathname.indexOf('/password/recovery') > -1) {
      return <PasswordRecoveryForm />;
    }

    return <SignInForm />;
  };

  return <>{renderForm()}</>;
};

export default SignInPage;
