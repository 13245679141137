import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PrimarySecurityShiftPopup from '../../components/PrimarySecurityShiftPopup';
import { useRootStore } from '../../store/RootStateContext';
import { ControlTable, LogsSection } from '../../components';
import CallPlayer from '../../components/CallPlayer';
import IncomingCallNotification from '../../components/IncomingCallNotification';
import './styles.less';

const SHIFT_MODAL_TITLE = <FormattedMessage id="modal.isItYourShift" />;
const SHIFT_MODAL_MSG = <FormattedMessage id="modal.msgShiftModal" />;

const DashboardPage = () => {
  const { tokBoxStore, authStore, callsStats } = useRootStore();
  const { Content } = Layout;

  const isAllDataCompleated = !authStore.isLoading && !callsStats.isLoading;

  const isPrimarySecurity =
    authStore.securityId === callsStats.primarySecurity.id;

  useEffect(() => {
    return () => {
      if (tokBoxStore.currentConnection) {
        tokBoxStore.closeCall(tokBoxStore.currentConnection.currentVuId);
      }
    };
  }, [tokBoxStore]);

  const closePopupPrimerySecurityChanged = () => {
    authStore.isOpenPopupPrimarySecurity = false;
    authStore.isUserDecision = true;
  };

  return (
    <Content className="dashboard-wrapper">
      <>
        {isAllDataCompleated &&
          authStore.isFirstLaunch &&
          authStore.isSecurity &&
          !isPrimarySecurity && (
            <PrimarySecurityShiftPopup
              title={SHIFT_MODAL_TITLE}
              body={SHIFT_MODAL_MSG}
            />
          )}
      </>
      <>
        {authStore.isOpenPopupPrimarySecurity && authStore.isSecurity && (
          <PrimarySecurityShiftPopup
            title={
              callsStats.primarySecurity.name ? (
                <FormattedMessage id="modal.primaryIsChanged" />
              ) : (
                <FormattedMessage id="modal.primaryTurnedOff" />
              )
            }
            body={
              callsStats.primarySecurity.name ? (
                <FormattedMessage
                  id="modal.withNameReturnPrimary"
                  values={{ name: callsStats.primarySecurity.name }}
                />
              ) : (
                <FormattedMessage id="modal.returnPrimary" />
              )
            }
            onDecline={closePopupPrimerySecurityChanged}
          />
        )}
      </>

      <div className="site-layout-wrapper">
        <>
          <div className="control-table-wrapper">
            <ControlTable />
          </div>
          <div className="log-section-wrapper">
            {tokBoxStore.isStreamActive || tokBoxStore.isIncoming ? (
              <CallPlayer />
            ) : (
              <LogsSection />
            )}
            {tokBoxStore.incomingCallsList.length
              ? tokBoxStore.incomingCallsList.map((el) => (
                  <IncomingCallNotification el={el} key={el.timestamp} />
                ))
              : null}
          </div>
        </>
      </div>
    </Content>
  );
};

export default observer(DashboardPage);
