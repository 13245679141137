import React from 'react';

import { Layout } from 'antd';

import { SettingsSection } from '../../components';

import './styles.less';

const SettingsPage = () => {
  const { Content } = Layout;

  return (
    <Content className="settings-page-wrapper">
      <div className="settings-layout-wrapper">
        <SettingsSection />
      </div>
    </Content>
  );
};

export default SettingsPage;
