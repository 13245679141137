import React from 'react';
import { Layout } from 'antd';

import NotificationDrawer from '../NotificationDrawer';
import AvatarMenu from '../AvatarMenu';
import HeaderNavigationMenu from '../HeaderNavigationMenu';
import BurgerMenu from '../BurgerMenu';
import CallSettingsInfo from '../CallSettingsInfo';
import StatusSecurity from '../StatusSecurity';
import LangSwitch from '../LangSwitch/LangSwitch';
import CompanyList from '../CompanyList/CompanyList';
import s from './HeaderSection.module.css';

import './styles.less';

const HeaderSection = () => {
  const { Header } = Layout;

  return (
    <Header className="header-layout-wrapper">
      <div className="header-lang">
        <BurgerMenu />
        <HeaderNavigationMenu />
        <div className={s.dropdown}>
          <CompanyList />
        </div>
        <div className={s.dropdown}>
          <LangSwitch />
        </div>
      </div>
      <div className="menu-wrapper">
        <StatusSecurity />
        <CallSettingsInfo />
        <NotificationDrawer />
        <AvatarMenu />
      </div>
    </Header>
  );
};

export default HeaderSection;
