import { notification } from 'antd';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import notsSfx from '../assets/sounds/notification_sound.mp3';
import { LOCALES } from '../i18n/index';

const showNotificationToster = (
  title,
  message,
  type = 'success',
  timestamp = null,
  classes = ''
) => {
  let parsedMsg = message;

  if (timestamp) {
    moment.locale(localStorage.getItem('lang') || LOCALES.ENGLISH);
    parsedMsg = `${moment.unix(timestamp).utc(0).format('HH:mm')} ${message}`;
  }

  if (type === 'warn') {
    const notificationSound = new Audio(notsSfx);
    notificationSound.play();
  }

  notification[type]({
    className: classes,
    message: title,
    description: parsedMsg,
    style: {
      width: 600,
    },
  });
};

export default showNotificationToster;
