import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRootStore } from '../../store/RootStateContext';

import s from './CallWidget.module.css';

const CallWidget = ({ callPlayerId = 'callPlayer' }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isImgLoaded, setImgIsLoaded] = useState(false);
  const [playerEl, setPlayerEl] = useState(null);
  const [playerElPosition, setPlayerElPosition] = useState(null);
  const { tokBoxStore } = useRootStore();
  const history = useHistory();

  useEffect(() => {
    const img = new Image();
    img.src = tokBoxStore.incomingCallImage;
    img.onload = () => {
      setImgIsLoaded(true);
    };
    if (!tokBoxStore.incomingCallImage) {
      setImgIsLoaded(false);
    }
  }, [tokBoxStore.incomingCallImage]);

  useEffect(() => {
    if (tokBoxStore.isCallPlayerShowing) {
      const targetElement = document.getElementById(callPlayerId);
      setPlayerEl(targetElement);
    } else {
      setPlayerEl(null);
    }
  }, [tokBoxStore.isCallPlayerShowing, callPlayerId]);

  useEffect(() => {
    // initial scroll after redirection if callPlayer exists
    if (playerEl) {
      playerEl.scrollIntoView();
    }
  }, [playerEl]);

  useEffect(() => {
    const handleScroll = () => {
      const positon = playerEl.getBoundingClientRect().top;
      setPlayerElPosition(positon);
    };

    if (playerEl) {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [playerEl]);

  useEffect(() => {
    if (playerEl) {
      const positon = playerEl.getBoundingClientRect().top;
      setPlayerElPosition(positon);
    } else {
      setPlayerElPosition(null);
    }
  }, [playerEl]);

  useEffect(() => {
    if (playerEl && playerElPosition) {
      const windowHeight = window.innerHeight;

      const thirdHeightOfPlayer = playerEl.clientHeight / 3;

      const visibleHeightOfPlayer = windowHeight - playerElPosition;

      if (visibleHeightOfPlayer < thirdHeightOfPlayer) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  }, [playerEl, playerElPosition]);

  useEffect(() => {
    if (tokBoxStore.isIncoming && isImgLoaded && !playerEl) {
      setIsVisible(true);
    } else setIsVisible(false);
  }, [
    playerEl,
    tokBoxStore.isIncoming,
    tokBoxStore.isStreamActive,
    isImgLoaded,
  ]);

  const handleClick = () => {
    if (playerEl) {
      playerEl.scrollIntoView();
    }
    if (!playerEl) {
      history.push('/app/dashboard');
    }
  };

  if (isVisible && tokBoxStore.incomingCallImage) {
    return (
      <div
        className={s.container}
        style={{
          backgroundImage: `url(${tokBoxStore.incomingCallImage})`,
        }}
        onClick={handleClick}
      />
    );
  }

  return null;
};

CallWidget.propTypes = {
  callPlayerId: PropTypes.string,
};

CallWidget.defaultProps = {
  callPlayerId: 'callPlayer',
};

export default observer(CallWidget);
