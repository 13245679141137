import React from 'react';
import { Form, Button, Input } from 'antd';
import PropTypes from 'prop-types';

const CustomForm = ({
  fields,
  onSubmit,
  formName,
  title,
  inputDisabled,
  inputType,
  size,
  formClassName,
}) => {
  const [form] = Form.useForm();

  const inputFieldType = (type, placeholder, prefix) => {
    if (type === 'password') {
      return (
        <Input.Password
          disabled={inputDisabled}
          prefix={prefix}
          placeholder={placeholder}
        />
      );
    }

    return (
      <Input
        disabled={inputDisabled}
        prefix={prefix}
        placeholder={placeholder}
      />
    );
  };

  return (
    <div className={formClassName}>
      <Form
        form={form}
        name={`${formName}Form`}
        fields={fields}
        onFinish={onSubmit || null}
        size={size}
      >
        {fields.map((el) => {
          const isPrefix = el.prefix ? el.prefix : undefined;
          const isPlaceholder = el.placeholder ? el.placeholder : undefined;

          return (
            <Form.Item
              key={el.name}
              name={el.name}
              label={el.label}
              rules={el.rules}
              dependencies={el.dependencies}
              hasFeedback={el.hasFeedback}
            >
              {inputFieldType(inputType, isPlaceholder, isPrefix)}
            </Form.Item>
          );
        })}
        {title && (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {title}
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
};

CustomForm.defaultProps = {
  fields: [
    {
      name: '',
      value: '',
      label: '',
    },
  ],
  title: '',
  size: '',
  formClassName: '',
  onSubmit: null,
  inputDisabled: false,
  inputType: 'input',
};

CustomForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  size: PropTypes.string,
  formClassName: PropTypes.string,
  inputType: PropTypes.string,
  inputDisabled: PropTypes.bool,
  formName: PropTypes.string.isRequired,
};

export default CustomForm;
