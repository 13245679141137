import React from 'react';
import { Menu } from 'antd';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { AppstoreOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import './styles.less';

const HeaderNavigationMenu = () => {
  const { location } = useHistory();

  return (
    <div className="navigation-wrapper">
      <Menu
        theme="dark"
        mode="inline"
        className="navigation-menu-wrapper"
        selectedKeys={[location.pathname]}
      >
        <Menu.Item
          className="nav-menu-list-item"
          key="/app/dashboard"
          icon={<AppstoreOutlined />}
          title=""
        >
          <Link to="/app/dashboard">
            <span>
              <FormattedMessage id="headerNavMenu.dashboard" />
            </span>
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default observer(HeaderNavigationMenu);
