import React from 'react';
import { FormattedMessage } from 'react-intl';

const VALID_PASSWORD = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&/])[A-Za-z\d@$!%*#?&/]{8,}$/;
const VALID_EMAIL = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

const comparePasswords = (getFieldValue, compField) => ({
  validator(rule, value) {
    if (!value || getFieldValue(compField) === value) {
      return Promise.resolve();
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(<FormattedMessage id="services.msgErrorMatchPass" />);
  },
});

const validatePassword = () => ({
  validator(rule, value) {
    const isValid = value && !!value.match(VALID_PASSWORD);
    if (value && isValid) {
      return Promise.resolve();
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(
      <FormattedMessage id="services.msgErrorPassValidation" />
    );
  },
});

const validateEmail = () => ({
  validator(rule, value) {
    const isValid = value && !!value.match(VALID_EMAIL);
    if (!value || isValid) {
      return Promise.resolve();
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(<FormattedMessage id="services.wrongEmail" />);
  },
});

export { comparePasswords, validatePassword, validateEmail };
