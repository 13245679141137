import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import './styles.less';

const SelectMenu = ({
  list,
  selectedItem,
  onChange,
  placeholder,
  isDisabled,
  isOpen,
}) => {
  const customOpenProps = isOpen ? { open: true } : null;
  return (
    <>
      <Select
        className="select-wrapper"
        dropdownClassName="select-dropdown"
        defaultValue={selectedItem}
        onChange={(value) => onChange(value)}
        placeholder={placeholder}
        options={list}
        disabled={isDisabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...customOpenProps}
      />
    </>
  );
};

SelectMenu.defaultProps = {
  selectedItem: null,
  isDisabled: false,
  isOpen: false,
};

SelectMenu.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  selectedItem: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

export default observer(SelectMenu);
