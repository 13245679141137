const URL = process.env.REACT_APP_WS_APP_URL;

class Socket {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  errorHandler = null;

  subscriptions = {};

  connection = null;

  timerId = 0;

  reconnectAttempts = 0;

  timeLastReconnection = null;

  maxReconnectAttempts = 3;

  CONNECTION_REASON = {
    initialConnection: 'Initial Connection',
    reconnection: 'Reconnection',
  };

  reconnection = (reason = '') => {
    if (this.reconnectAttempts === this.maxReconnectAttempts) {
      this.rootStore.errorsStore.showSocketError();
      return;
    }

    if (reason === this.CONNECTION_REASON.reconnection) {
      if (Date.now() - this.timeLastReconnection < 60 * 1000) {
        this.reconnectAttempts += 1;
      } else {
        this.reconnectAttempts = 1;
      }
      this.timeLastReconnection = Date.now();
      this.establishConnection();
    }
  };

  establishConnection = () => {
    this.connection = new WebSocket(URL);
    this.connection.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      const cb = this.subscriptions[eventData.type];

      if (cb) {
        cb(eventData);
      }
    };

    this.connection.onopen = (e) => {
      this.rootStore.getInitDataAndSubscribe();
      // eslint-disable-next-line no-console
      console.log('Connection Opened! Code:', e.currentTarget.readyState);
      // this.keepAlive();
    };

    this.connection.onclose = (e) => {
      // eslint-disable-next-line no-console
      console.log(
        'Connection Closed! Code:',
        e.code,
        'Reason:',
        e.reason || 'none'
      );

      if (
        e.code !== 1000 &&
        this.reconnectAttempts <= this.maxReconnectAttempts
      ) {
        this.reconnection(this.CONNECTION_REASON.reconnection);
      }
    };

    this.connection.onerror = (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    };
  };

  send = (message) => {
    if (this.connection.readyState === 1) {
      this.connection.send(JSON.stringify(message));
    }
  };

  subscribe = (eventName, cb) => {
    this.subscriptions[eventName] = cb;
  };

  closeConnection = (code, reason) => {
    if (this.connection && this.connection.readyState === 1) {
      this.connection.close(code, reason);
    }
    this.subscriptions = {};
    this.connection = null;
  };
}

export default Socket;
