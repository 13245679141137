import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { MailOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import s from './PasswordRecoveryForm.module.css';

import LangSwitch from '../LangSwitch/LangSwitch';

import { useRootStore } from '../../store/RootStateContext';
import CustomForm from '../CustomForm';
import { validateEmail } from '../../services/validators';

import './styles.less';

const PasswordRecoveryForm = () => {
  const intl = useIntl();

  const RECOVERY_PASSWORD_FIELDS = [
    {
      name: 'email',
      rules: [
        {
          required: true,
          message: <FormattedMessage id="passRecoveryForm.msgEmptyEmail" />,
        },
        () => validateEmail(),
      ],
      hasFeedback: true,
      placeholder: intl.formatMessage({ id: 'passRecoveryForm.email' }),
      prefix: <MailOutlined className="site-form-item-icon" />,
    },
  ];

  const { authStore } = useRootStore();

  const onFinish = ({ email }) => {
    authStore.recoveryPasswordRequest(email);
  };

  useEffect(() => {
    return () => {
      authStore.passwordConfirmationMsg = '';
    };
  }, [authStore]);

  return (
    <>
      <div className="recovery-title">
        <div className={s.langSwitch}>
          <LangSwitch />
        </div>
        <FormattedMessage id="passRecoveryForm.recoveryScreen" />
      </div>
      {!authStore.passwordConfirmationMsg ? (
        <CustomForm
          formName="recovery"
          formClassName="recovery-form"
          fields={RECOVERY_PASSWORD_FIELDS}
          onSubmit={onFinish}
          inputDisabled={false}
          inputType="input"
          title={<FormattedMessage id="passRecoveryForm.sendRequest" />}
          size="large"
        />
      ) : (
        <div className="confirmationRecoveryMsg">
          <p>
            <FormattedMessage id="passRecoveryForm.confimationRequestSent" />
          </p>
        </div>
      )}
    </>
  );
};

export default observer(PasswordRecoveryForm);
