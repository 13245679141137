import React, { useState } from 'react';
import { Space, Radio, Statistic, InputNumber, Form } from 'antd';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import classnames from 'classnames';
import { FieldTimeOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import Modal from '../../Modal';
import { useRootStore } from '../../../store/RootStateContext';

import '../styles.less';

const DURATION_VALUES = [5, 15, 20, 30, 60];

const FetchTimerButton = ({ id, duration }) => {
  const [form] = Form.useForm();
  const { Countdown } = Statistic;
  const { controlTableStore } = useRootStore();
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [selectTime, setSelectTime] = useState();

  const getId = () => nanoid();

  const isDisabled = duration.available;
  const isModalAllowed = isModalOpen;

  const deadline = duration.elapsed ? moment.unix(duration.elapsed) : null;

  const onModalOpen = () => {
    if (isDisabled) {
      setModalIsOpen(true);
      controlTableStore.isTimerModalActive = true;
    }
  };

  const onModalClose = () => {
    setModalIsOpen(false);
    controlTableStore.isTimerModalActive = false;
  };

  const fetchTimer = () => {
    if (selectTime) {
      controlTableStore.setTimer(id, selectTime);
      onModalClose();
    }
  };

  const classes = classnames('table-btn-icon', {
    'negative-bgc': duration.elapsed === 'activated',
    'disabled-item': !duration.available,
  });

  const handleValuesChanges = (changedValues) => {
    if (changedValues.radioGroupTimeSetter) {
      form.setFieldsValue({ inlineTimeSetter: undefined });
      setSelectTime(changedValues.radioGroupTimeSetter);
    } else {
      form.setFieldsValue({ radioGroupTimeSetter: undefined });
      setSelectTime(changedValues.inlineTimeSetter);
    }
  };

  const timerGroup = (
    <div className="time-setter">
      <Form
        form={form}
        name="timeSetterForm"
        onValuesChange={handleValuesChanges}
      >
        <Form.Item
          label={<FormattedMessage id="timerBtn.labelTimeDuration" />}
          name="radioGroupTimeSetter"
        >
          <Radio.Group
            size="large"
            value={selectTime}
            onChange={(e) => setSelectTime(e.target.value)}
          >
            {DURATION_VALUES.map((el) => (
              <Radio.Button key={getId()} value={el}>
                {el}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="timerBtn.labelCustomDuration" />}
          name="inlineTimeSetter"
        >
          <InputNumber min={1} max={480} maxLength={3} />
        </Form.Item>
      </Form>
    </div>
  );

  const currentTimer = () => {
    if (deadline) {
      return (
        <Countdown
          className="timer negative-bgc"
          value={moment(deadline, 'HH:mm:ss').valueOf()}
          format="HH:mm:ss"
        />
      );
    }

    return <FieldTimeOutlined className={classes} />;
  };

  return (
    <>
      {isModalAllowed && (
        <Modal
          title={<FormattedMessage id="timerBtn.selectOrEnterTime" />}
          okBtnTxt={<FormattedMessage id="timerBtn.submit" />}
          onOk={fetchTimer}
          isOkDisabled={!selectTime}
          cancelBtnTxt={<FormattedMessage id="timerBtn.cancel" />}
          onCancel={onModalClose}
        >
          {timerGroup}
        </Modal>
      )}
      <Space size="large">
        <div onClick={onModalOpen} className="table-item">
          {currentTimer()}
        </div>
      </Space>
    </>
  );
};

FetchTimerButton.defaultProps = {
  duration: null,
};

FetchTimerButton.propTypes = {
  id: PropTypes.number.isRequired,
  duration: PropTypes.PropTypes.objectOf(PropTypes.any),
};

export default FetchTimerButton;
