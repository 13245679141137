import { observable, action, decorate } from 'mobx';
import showNotificationToster from '../services/showNotification';

class LogsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  logs = {};

  notifications = {};

  selectedNotification = {
    id: null,
    timeStamp: null,
    device_id: null,
    data: {
      device_name: '',
      details: '',
      event_id: null,
    },
  };

  setLog = (log) => {
    const newLog = {
      device_id: log.device_id,
      timestamp: log.timestamp,
      data: {
        device_name: log.data.device_name,
        details: log.data.details,
        event_id: log.data.event_id,
      },
    };

    if (this.logs[log.company_id]) {
      this.logs[log.company_id].unshift(newLog);
    } else {
      this.logs[log.company_id] = [newLog];
    }
  };

  setNotification = (notification) => {
    const { company_id: companyId } = notification;

    const newNotification = {
      device_id: notification.device_id,
      timestamp: notification.timestamp,
      data: {
        device_name: notification.data.device_name,
        details: notification.data.details,
        event_id: notification.data.event_id,
      },
    };

    if (this.notifications[companyId]) {
      this.notifications[companyId].unshift(newNotification);
    } else {
      this.notifications[companyId] = [newNotification];
    }

    showNotificationToster(
      notification.data.device_name,
      notification.data.details,
      'warn',
      notification.timestamp,
      'notifications-custom'
    );
  };

  setNotificationsList = (list) => {
    const companyId = this.rootStore.companiesStore.currentCompanyId;
    this.notifications[companyId] = list;
  };

  subscribe = () => {
    this.rootStore.socket.subscribe('notification_list', ({ data }) =>
      this.setNotificationsList(data)
    );
    this.rootStore.socket.subscribe('log_message', (log) => this.setLog(log));
    this.rootStore.socket.subscribe('notification_message', (notification) =>
      this.setNotification(notification)
    );
  };

  getInitialData = () => {
    this.rootStore.socket.send({ activity: 'action_get_notifications' });
  };

  setSelectedNotification = (id) => {
    const companyId = this.rootStore.companiesStore.currentCompanyId;

    this.selectedNotification = this.notifications[companyId].find(
      (log) => log.data.event_id === id
    );
  };

  clearLogs = () => {
    this.logs = {};
  };

  deleteNotification = (eventId) => {
    const companyId = this.rootStore.companiesStore.currentCompanyId;

    this.setSelectedNotificationRead(eventId);
    this.notifications[companyId] = this.notifications[companyId].filter(
      (el) => el.data.event_id !== eventId
    );
  };

  deleteAllNotifications = () => {
    const companyId = this.rootStore.companiesStore.currentCompanyId;
    const storedNotifications = this.notifications[companyId] || [];
    this.notifications[companyId] = {};
    storedNotifications.filter((el) =>
      this.setSelectedNotificationRead(el.data.event_id)
    );
  };

  setSelectedNotificationRead = (id) => {
    this.rootStore.socket.send({
      activity: 'action_notification_read',
      event_id: id,
    });
  };
}

export default decorate(LogsStore, {
  logs: observable,
  selectedNotification: observable,
  notifications: observable,
  setSelectedNotification: action,
  setNotificationsList: action,
  getInitialData: action,
  setLog: action,
  setNotification: action,
  clearLogs: action,
  deleteNotification: action,
  deleteAllNotifications: action,
});
