import { observable, action, decorate, computed } from 'mobx';

class SettingsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  tenantsList = [];

  selectedTenantId = null;

  selectedTenantFirstName = '';

  selectedTenantLastName = '';

  isRollover = false;

  isRedirection = false;

  isSettingsLoading = true;

  isSettingChanging = false;

  get selectedTenantFullName() {
    return `${this.selectedTenantFirstName} ${this.selectedTenantLastName}`;
  }

  get filteredTenantsList() {
    return this.tenantsList.map((el) => ({
      label: `${el.first_name} ${el.last_name}`,
      value: el.id,
    }));
  }

  getData = async () => {
    this.isSettingsLoading = true;
    await this.getTenantsList();
    await this.getInitSettings();
    this.isSettingsLoading = false;
  };

  getInitSettings = async () => {
    const res = await this.rootStore.makeRequest('get', 'user/call/settings/');
    const {
      is_redirection,
      is_rollover,
      tenant,
      tenant_first_name,
      tenant_last_name,
    } = res.data;
    this.selectedTenantId = tenant;
    this.selectedTenantFirstName = tenant_first_name;
    this.selectedTenantLastName = tenant_last_name;
    this.isRollover = is_rollover;
    this.isRedirection = is_redirection;
  };

  getTenantsList = async () => {
    const res = await this.rootStore.makeRequest('get', 'companies/tenants/');
    const { call_transaction_list } = res.data;
    this.tenantsList = call_transaction_list;
  };

  applyChanges = async () => {
    this.isSettingChanging = true;
    await this.rootStore.makeRequest('put', 'user/call/settings/', {
      is_rollover: this.isRollover,
      is_redirection: this.isRedirection,
      tenant: this.selectedTenantId,
    });
    this.isSettingChanging = false;
  };
}

export default decorate(SettingsStore, {
  tenantsList: observable,
  selectedTenantId: observable,
  isSettingsLoading: observable,
  selectedTenantFirstName: observable,
  selectedTenantLastName: observable,
  isRollover: observable,
  isRedirection: observable,
  isSettingChanging: observable,
  selectedTenantFullName: computed,
  filteredTenantsList: computed,
  getData: action,
  getTenantsList: action,
  getInitSettings: action,
  applyChanges: action,
});
