import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

const Switcher = ({ children, isChecked, onChange, isDisabled }) => {
  return (
    <div className="switch-wrapper">
      <Switch
        className="switch-btn"
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
      />
      {children}
    </div>
  );
};

Switcher.defaultProps = {
  isDisabled: false,
};

Switcher.propTypes = {
  children: PropTypes.node.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Switcher;
