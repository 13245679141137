import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';

import { useRootStore } from '../../store/RootStateContext';
import settings from './configuration/settings';

import './styles.less';

const ControlTable = () => {
  const { controlTableStore } = useRootStore();

  return (
    <Table
      className="control-table"
      pagination={false}
      columns={settings}
      dataSource={controlTableStore.tableData}
      rowKey="device_id"
      scroll={{ x: '100%' }}
      loading={controlTableStore.isLoading}
    />
  );
};

export default observer(ControlTable);
