import { useEffect } from 'react';
import { Howl } from 'howler';
import { observer } from 'mobx-react';
import { useRootStore } from '../../store/RootStateContext';
import incomingSfx from '../../assets/sounds/incoming_call_ringtone.mp3';

const ringtone = new Howl({
  src: [incomingSfx],
  autoplay: false,
  loop: true,
  volume: 0.5,
});

const CallRinging = () => {
  const { tokBoxStore } = useRootStore();

  const { isIncoming, isRinging, setIsRinging } = tokBoxStore;

  useEffect(() => {
    if (isIncoming) {
      setIsRinging(true);
    }

    if (!isIncoming) {
      setIsRinging(false);
    }
    return () => {
      setIsRinging(false);
    };
  }, [isIncoming, setIsRinging]);

  useEffect(() => {
    if (isRinging) {
      ringtone.stop();
      ringtone.play();
    } else {
      ringtone.stop();
    }
  }, [isRinging]);

  return null;
};

export default observer(CallRinging);
