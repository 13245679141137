import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import '../styles.less';

const STATUSES = [
  {
    key: 'good',
    value: <FormattedMessage id="controlTableConfig.good" />,
  },
  {
    key: 'open',
    value: <FormattedMessage id="controlTableConfig.open" />,
  },
  {
    key: 'closed',
    value: <FormattedMessage id="controlTableConfig.closed" />,
  },
  {
    key: 'locked',
    value: <FormattedMessage id="controlTableConfig.locked" />,
  },
  {
    key: 'unlocked',
    value: <FormattedMessage id="controlTableConfig.unlocked" />,
  },
  {
    key: 'lockdown',
    value: <FormattedMessage id="controlTableConfig.lockdown" />,
  },
  {
    key: 'disabled',
    value: <FormattedMessage id="controlTableConfig.disabled" />,
  },
  {
    key: 'NA',
    value: <FormattedMessage id="controlTableConfig.NA" />,
  },
  {
    key: 'connected',
    value: <FormattedMessage id="controlTableConfig.connected" />,
  },
  {
    key: 'not_connected',
    value: <FormattedMessage id="controlTableConfig.notConnected" />,
  },
];

const FetchStatus = ({ status }) => {
  const classes = classnames({
    negative:
      status === 'open' || status === 'unlocked' || status === 'lockdown',
    positive:
      status === 'good' ||
      status === 'locked' ||
      status === 'closed' ||
      status === 'connected',
    neutral: status === 'NA' || status === 'disabled',
    warning: status === 'not_connected',
  });

  return (
    <div className={classes}>
      {STATUSES.find((el) => el.key === status).value}
    </div>
  );
};

FetchStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default FetchStatus;
