import axios from 'axios';
import showNotificationToster from './showNotification';
import getIntl from '../i18n/intl';
import { LOCALES } from '../i18n/index';

const API_BASE_URL = process.env.REACT_APP_URL;

const catchResponseSuccesses = (response) => {
  if (
    response.config.url === 'user/call/settings/' &&
    response.config.method === 'put'
  ) {
    showNotificationToster(
      getIntl().formatMessage({ id: 'services.settingsChanges' }),
      getIntl().formatMessage({ id: 'services.settingsAppliedSuccessfully' })
    );
  }

  if (
    response.config.url === 'user/password/change/' &&
    response.config.method === 'post'
  ) {
    showNotificationToster(
      getIntl().formatMessage({ id: 'services.profileChanges' }),
      getIntl().formatMessage({ id: 'services.passChangededSuccessfully' })
    );
  }
};

const catchResponseError = ({ response }) => {
  if (!response) return;

  const resData =
    response && response.data && response.status !== '404'
      ? response.data
      : false;

  if (resData && response.config.url === 'user/password/change/') {
    // eslint-disable-next-line prefer-destructuring
    const message = resData[Object.keys(resData)[0]][0];
    showNotificationToster(
      getIntl().formatMessage({ id: 'services.passChangeError' }),
      message,
      'error'
    );
  }

  if (resData && response.config.url === 'user/password/recovery/') {
    // eslint-disable-next-line prefer-destructuring
    const message = resData[Object.keys(resData)[0]][0];
    showNotificationToster(
      getIntl().formatMessage({ id: 'services.passRecoveryError' }),
      message,
      'error'
    );
  }

  if (resData && response.config.url === 'user/call/settings/') {
    const message = resData[Object.keys(resData)[0]];
    showNotificationToster(
      getIntl().formatMessage({ id: 'services.settingsChangeError' }),
      message,
      'error'
    );
  }

  if (resData && response.config.url === '/user/password/recovery/confirm/') {
    const message = resData[Object.keys(resData)[0]][0];
    showNotificationToster(
      getIntl().formatMessage({ id: 'services.passRecoveryError' }),
      message,
      'error'
    );
  }

  if (resData && response.config.url.includes('user/token/')) {
    showNotificationToster(
      getIntl().formatMessage({ id: 'services.authorizationError' }),
      getIntl().formatMessage({ id: 'services.msgIncorrectEmail' }),
      'error'
    );
  }

  if (resData && response.config.url.includes('password/set/')) {
    const message = resData[Object.keys(resData)[0]][0];
    showNotificationToster(
      getIntl().formatMessage({ id: 'services.confirmationError' }),
      message,
      'error'
    );
  }

  if (!resData) {
    showNotificationToster(
      getIntl().formatMessage({ id: 'services.unknownError' }),
      getIntl().formatMessage(
        { id: 'notificationDrawer.visitorUnit' },
        { status: response.status }
      ),
      'error'
    );
  }
};

const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    // eslint-disable-next-line no-param-reassign
    config.headers['accept-language'] =
      localStorage.getItem('lang') || LOCALES.ENGLISH;

    if (token && !config.headers.Authorization) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    catchResponseSuccesses(response);
    return response;
  },
  (error) => {
    catchResponseError(error);
    return Promise.reject(error);
  }
);

export default instance;
