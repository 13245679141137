import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Button } from 'antd';
import s from './SignInForm.module.css';

import LangSwitch from '../LangSwitch/LangSwitch';

import { useRootStore } from '../../store/RootStateContext';
import './styles.less';

const SignInForm = () => {
  const intl = useIntl();
  const { authStore } = useRootStore();

  const onFinish = ({ email, password }) => {
    authStore.getInitData(email, password);
  };

  return (
    <div className="sign-in-form-wrapper">
      <div className={s.langSwitch}>
        <LangSwitch />
      </div>
      <Form
        name="login-form"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        size="large"
      >
        <div className="title">
          <FormattedMessage id="signInForm.logInScreen" />
        </div>
        <div className="fields-wrapper">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="signInForm.msgEmptyEmail" />,
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder={intl.formatMessage({ id: 'signInForm.email' })}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="signInForm.msgEmptyPass" />,
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={intl.formatMessage({ id: 'signInForm.pass' })}
              />
            </Form.Item>
            <Link className="login-form-forgot" to="/password/recovery">
              <FormattedMessage id="signInForm.forgotPass" />
            </Link>
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            <FormattedMessage id="signInForm.login" />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(SignInForm);
