import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const Modal = (props) => {
  const {
    title,
    onOk,
    isOkDisabled,
    onCancel,
    okBtnTxt,
    cancelBtnTxt,
    children,
  } = props;

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-title">{title}</div>
        <div className="modal-content">{children}</div>
        <div className="modal-footer">
          {cancelBtnTxt && (
            <button className="modal-btn" type="button" onClick={onCancel}>
              <span className="btn-text">{cancelBtnTxt}</span>
            </button>
          )}
          {okBtnTxt && (
            <button
              disabled={isOkDisabled}
              className="modal-btn"
              type="button"
              onClick={onOk}
            >
              <span className="btn-text">{okBtnTxt}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  isOkDisabled: false,
  onOk: null,
  okBtnTxt: '',
  onCancel: null,
  cancelBtnTxt: '',
};

Modal.propTypes = {
  okBtnTxt: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onOk: PropTypes.func,
  cancelBtnTxt: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onCancel: PropTypes.func,
  isOkDisabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
