import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';

import App from './App';
import { StoreProvider } from './store/RootStateContext';
import routing from './store/routing';
import RootStore from './store/RootStore';

import './index.less';

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routing);

const stores = new RootStore();

if (localStorage.token) {
  stores.authStore.authUser = localStorage.token;
  document.cookie = `authorization=Token ${localStorage.token};path=/`;
  stores.authStore.getUserInfo();
}

ReactDOM.render(
  <StoreProvider stores={stores}>
    <App history={history} />
  </StoreProvider>,
  document.getElementById('root')
);
