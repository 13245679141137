import { observable, decorate, action } from 'mobx';

class CallsStats {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  redirect = null;

  rollover = null;

  vu_number = null;

  isLoading = true;

  primarySecurity = { id: null, name: null };

  resetCallStats = () => {
    this.redirect = null;
    this.rollover = null;
    this.vu_number = null;
    this.isLoading = true;
    this.primarySecurity = { id: null, name: null };
  };

  setCallStats = (data) => {
    this.isLoading = false;
    this.redirect = data.redirect;
    this.rollover = data.rollover;
    this.vu_number = data.vu_number;
    this.primarySecurity = {
      id: data.primary_security_id,
      name: data.primary_security_name,
    };
  };

  subscribe = () => {
    this.rootStore.socket.subscribe('calls_stats', (data) => {
      this.setCallStats(data);
    });
  };

  getCallsStats = () => {
    this.isLoading = true;
    this.rootStore.socket.send({ activity: 'action_calls_stats' });
  };
}

export default decorate(CallsStats, {
  redirect: observable,
  rollover: observable,
  vu_number: observable,
  primarySecurity: observable,
  setCallStats: action,
  subscribe: action,
  getCallsStats: action,
});
