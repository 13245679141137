import React from 'react';
import { Menu, Avatar, Dropdown } from 'antd';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { useRootStore } from '../../store/RootStateContext';

import './styles.less';

const AvatarMenu = () => {
  const { authStore } = useRootStore();

  const menu = (
    <Menu>
      <Menu.Item key="profile" title="">
        <Link to="/app/profile">
          <UserOutlined className="avatar-menu-icon" />
          <FormattedMessage id="menu.profile" />
        </Link>
      </Menu.Item>
      {authStore.isSecurity && (
        <Menu.Item key="setting" title="">
          <Link to="/app/settings">
            <SettingOutlined className="avatar-menu-icon" />
            <FormattedMessage id="menu.setting" />
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="logout" title="" onClick={authStore.logOutUser}>
        <LogoutOutlined className="avatar-menu-icon" />
        <FormattedMessage id="menu.logout" />
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="avatar-wrapper">
      <Dropdown overlay={menu} trigger={['click']}>
        <Avatar size="large" icon={<UserOutlined />} />
      </Dropdown>
    </div>
  );
};

export default observer(AvatarMenu);
