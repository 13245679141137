import React from 'react';
import { observer } from 'mobx-react';
import { Space, Spin } from 'antd';
import { ClusterOutlined, LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import callStates from '../../../util/callStates';

import { useRootStore } from '../../../store/RootStateContext';
import Modal from '../../Modal';

import '../styles.less';

const CallButton = ({ id, status, deviceName }) => {
  const { tokBoxStore } = useRootStore();

  const isAllowed = status !== 'disabled' && status !== 'activated';
  const isSpinning =
    tokBoxStore.currentConnection &&
    tokBoxStore.currentConnection.showSpinner &&
    tokBoxStore.currentConnection.currentVuId === id;
  const isConnected =
    tokBoxStore.currentConnection &&
    tokBoxStore.currentConnection.callState === callStates.CONNECTED &&
    tokBoxStore.currentConnection.currentVuId === id;
  const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const classes = classnames('table-item', {
    'negative-bgc': status === 'activated' && !isConnected,
    'positive-bgc': isConnected,
    'disabled-item': status === 'disabled',
  });

  const onStartCall = () => {
    if (isAllowed) {
      tokBoxStore.startCall(id, deviceName);
      return;
    }
    if (status !== 'disabled') {
      tokBoxStore.closeCall(id);
    }
  };

  const onChangeConnect = () => {
    tokBoxStore.changeCall(deviceName);
  };

  const onReconnectCancel = () => {
    tokBoxStore.isChangeSession = false;
  };

  return (
    <>
      {tokBoxStore.isChangeSession && (
        <Modal
          title={<FormattedMessage id="controlTableCall.warning" />}
          okBtnTxt={<FormattedMessage id="controlTableCall.connect" />}
          onOk={onChangeConnect}
          cancelBtnTxt={<FormattedMessage id="controlTableCall.cancel" />}
          onCancel={onReconnectCancel}
        >
          <FormattedMessage id="controlTableCall.msgModal" />
        </Modal>
      )}
      <Space size="large">
        <Spin spinning={isSpinning} indicator={spinnerIcon}>
          <div className={classes} onClick={onStartCall}>
            <ClusterOutlined className="table-btn-icon" />
          </div>
        </Spin>
      </Space>
    </>
  );
};

CallButton.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
};

export default observer(CallButton);
