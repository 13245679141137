import React, { useState } from 'react';
import { Menu, Drawer } from 'antd';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import {
  MenuOutlined,
  AppstoreOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { useRootStore } from '../../store/RootStateContext';

import './styles.less';

const BurgerMenu = () => {
  const { authStore } = useRootStore();
  const { location } = useHistory();
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);

  const showDrawer = () => {
    setOpenBurgerMenu(true);
  };
  const onClose = () => {
    setOpenBurgerMenu(false);
  };

  return (
    <div className="burger-wrapper">
      <button type="button" className="burger-menu-btn" onClick={showDrawer}>
        <MenuOutlined className="burger-menu-icon" />
      </button>
      <Drawer
        className="burger-drawer-customs"
        title="Security Application"
        placement="left"
        closable
        onClose={onClose}
        visible={openBurgerMenu}
        footer={
          <Menu theme="dark" className="burger-menu-wrapper">
            <Menu.Item key="logout" title="" onClick={authStore.logOutUser}>
              <LogoutOutlined className="burger-menu-icon" />
              <FormattedMessage id="menu.logout" />
            </Menu.Item>
          </Menu>
        }
      >
        <Menu
          theme="dark"
          mode="vertical"
          className="burger-menu-wrapper"
          selectedKeys={[location.pathname]}
        >
          <Menu.Item
            className="burger-nav-menu-list-item"
            key="/app/dashboard"
            icon={<AppstoreOutlined />}
            title=""
          >
            <Link to="/app/dashboard">
              <span>
                <FormattedMessage id="menu.dashboard" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/app/profile" title="">
            <Link to="/app/profile">
              <UserOutlined className="burger-menu-icon" />
              <FormattedMessage id="menu.profile" />
            </Link>
          </Menu.Item>
          {authStore.isSecurity && (
            <Menu.Item key="/app/settings" title="">
              <Link to="/app/settings">
                <SettingOutlined className="burger-menu-icon" />
                <FormattedMessage id="menu.setting" />
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </Drawer>
    </div>
  );
};

export default observer(BurgerMenu);
