/* eslint-disable react/prop-types */
import React from 'react';
import {
  DisconnectOutlined,
  UserOutlined,
  UnlockOutlined,
  SplitCellsOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import FetchTimerButton from './FetchTimerButton';
import CallButton from './CallButton';
import FetchStatus from './FetchStatus';
import FetchButton from './FetchButton';

import '../styles.less';

const ControlTableColumnsSettings = [
  {
    title: <FormattedMessage id="controlTableSettings.visitorUnit" />,
    dataIndex: 'device_name',
    className: 'vu-name-cell',
    fixed: true,
  },
  {
    title: <FormattedMessage id="controlTableSettings.lock" />,
    key: 'lock',
    align: 'center',
    className: 'status-cell',
    render: ({ lock }) => {
      return <FetchStatus status={lock} />;
    },
  },
  {
    title: <FormattedMessage id="controlTableSettings.door" />,
    key: 'door',
    align: 'center',
    className: 'status-cell',
    render: ({ door }) => <FetchStatus status={door} />,
  },
  {
    title: <FormattedMessage id="controlTableSettings.doorControllerStatus" />,
    key: 'door_controller',
    align: 'center',
    className: 'status-cell',
    render: ({ door_controller }) => <FetchStatus status={door_controller} />,
  },
  {
    title: <FormattedMessage id="controlTableSettings.networkStatus" />,
    key: 'status',
    align: 'center',
    className: 'status-cell',
    render: ({ status }) => <FetchStatus status={status} />,
  },
  {
    title: <FormattedMessage id="controlTableSettings.videoConnect" />,
    key: 'device_id',
    align: 'center',
    className: 'cell',
    render: ({ connect, device_id, device_name }) => (
      <CallButton status={connect} id={device_id} deviceName={device_name} />
    ),
  },
  {
    title: <FormattedMessage id="controlTableSettings.openDoor" />,
    key: 'open_door',
    align: 'center',
    className: 'cell',
    render: ({ door_open, device_id }) => (
      <FetchButton title="OPEN_DOOR" status={door_open} id={device_id}>
        <SplitCellsOutlined className="table-btn-icon" />
      </FetchButton>
    ),
  },
  {
    title: <FormattedMessage id="controlTableSettings.unlock" />,
    key: 'unlock',
    align: 'center',
    className: 'cell',
    render: ({ unlock, device_id }) => (
      <FetchButton title="UNLOCK" status={unlock} id={device_id}>
        <DisconnectOutlined className="table-btn-icon" />
      </FetchButton>
    ),
  },
  {
    title: <FormattedMessage id="controlTableSettings.timer" />,
    key: 'timer',
    align: 'center',
    className: 'cell',
    render: ({ timer, device_id }) => (
      <FetchTimerButton title="TIMER" duration={timer} id={device_id} />
    ),
  },
  {
    title: <FormattedMessage id="controlTableSettings.disableVisiror" />,
    key: 'disable_visitors',
    align: 'center',
    className: 'cell',
    render: ({ disable_visitors, device_id }) => (
      <FetchButton
        title="DISABLE_VISITOR"
        status={disable_visitors}
        id={device_id}
      >
        <UserOutlined className="table-btn-icon" />
      </FetchButton>
    ),
  },
  {
    title: <FormattedMessage id="controlTableSettings.lockdown" />,
    key: 'lockdown',
    align: 'center',
    className: 'cell lockdown-thead',
    render: ({ lockdown, device_id }) => (
      <FetchButton title="LOCKDOWN" status={lockdown} id={device_id}>
        <UnlockOutlined className="table-btn-icon" />
      </FetchButton>
    ),
  },
];

export default ControlTableColumnsSettings;
