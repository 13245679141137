import { decorate, action, observable } from 'mobx';

class ErrorsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  isSocketsErrorShown = false;

  showSocketError = () => {
    this.isSocketsErrorShown = true;
  };

  hideSocketError = () => {
    this.isSocketsErrorShown = false;
  };
}

export default decorate(ErrorsStore, {
  isSocketsErrorShown: observable,
  showSocketError: action,
  hideSocketError: action,
});
