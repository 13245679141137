import { observable, decorate, action, computed } from 'mobx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LOCALES } from '../i18n';

class AuthStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  securityId = null;

  authUser = null;

  email = '';

  firstName = '';

  lastName = '';

  isSecurity = false;

  registrationDate = null;

  lastLogin = null;

  errorMsg = '';

  passwordConfirmationMsg = '';

  isPrimarySecurity = false;

  isFirstLaunch = true;

  isOpenPopupPrimarySecurity = false;

  isUserDecision = false;

  isLoading = false;

  lang = localStorage.lang || LOCALES.ENGLISH;

  get userFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get profileInfo() {
    return [
      {
        name: 'profileEmail',
        value: this.email,
        label: <FormattedMessage id="auth.email" />,
      },
      {
        name: 'profileFirstName',
        value: this.firstName,
        label: <FormattedMessage id="auth.firstName" />,
      },
      {
        name: 'profileLastName',
        value: this.lastName,
        label: <FormattedMessage id="auth.lastName" />,
      },
    ];
  }

  get profileActivityInfo() {
    return [
      {
        name: 'registrationDate',
        value: new Date(this.registrationDate).toLocaleString(),
        label: <FormattedMessage id="auth.registrationDate" />,
      },
      {
        name: 'lastLoginDate',
        value: new Date(this.lastLogin).toLocaleString(),
        label: <FormattedMessage id="auth.lastLoginDate" />,
      },
    ];
  }

  resetSecurityData = () => {
    this.isSecurity = false;
    this.isFirstLaunch = true;
    this.isPrimarySecurity = false;
  };

  getInitData = async (email, password) => {
    const res = await this.rootStore.makeRequest('post', 'user/token/', {
      email: email.trim(),
      password,
    });

    let token = null;

    if (res) {
      token = res.data.token;
      this.authUser = token;
      localStorage.setItem('token', token);
      document.cookie = `authorization=Token ${localStorage.token};path=/`;
      this.getUserInfo();
    }
  };

  logOutUser = () => {
    this.securityId = null;
    this.firstName = '';
    this.lastName = '';
    this.isSecurity = false;
    this.isPrimarySecurity = false;
    this.isOpenPopupPrimarySecurity = false;
    this.isUserDecision = false;
    this.authUser = null;
    this.email = null;
    this.setFirstLaunch(true);
    localStorage.removeItem('token');
    localStorage.removeItem('opentok_client_id');
    document.cookie = 'authorization = deleted';
    this.rootStore.socket.closeConnection(1000, 'logout');
  };

  getUserInfo = async () => {
    const res = await this.rootStore.makeRequest('get', `user/me/`);

    const resLanguage = await this.rootStore.makeRequest(
      'get',
      `user/language/`
    );

    if (
      resLanguage.status !== 401 &&
      resLanguage.status !== 400 &&
      resLanguage.status !== 403
    ) {
      const language = resLanguage.data.abbreviation.toLowerCase();
      this.lang = language;
      localStorage.setItem('lang', language);
    }

    if (res.status !== 401 && res.status !== 400 && res.status !== 403) {
      const {
        security_id,
        first_name,
        last_name,
        email,
        date_joined,
        last_login,
        role,
        is_primary_security,
      } = res.data;
      this.securityId = security_id;
      this.firstName = first_name;
      this.lastName = last_name;
      this.email = email;
      this.registrationDate = date_joined;
      this.lastLogin = last_login;
      this.isSecurity = role === 'security';
      this.isPrimarySecurity = is_primary_security;
      await this.rootStore.socket.establishConnection('initial connection');
    }
  };

  changePassword = async (oldPassword, newPassword1, newPassword2) => {
    await this.rootStore.makeRequest('post', 'user/password/change/', {
      old_password: oldPassword,
      new_password1: newPassword1,
      new_password2: newPassword2,
    });
  };

  subscribe = () => {
    this.rootStore.socket.subscribe('logout', () => this.logOutUser());
    this.rootStore.socket.subscribe('primary_security', this.setSecurityShift);
  };

  recoveryPasswordRequest = async (email) => {
    const res = await this.rootStore.makeRequest(
      'post',
      'user/password/recovery/',
      {
        email,
      }
    );

    if (res) {
      this.passwordConfirmationMsg = res.data.detail;
    }
  };

  confirmPassword = async (uid, token, newPassword1, newPassword2) => {
    const res = await this.rootStore.makeRequest('post', 'user/password/set/', {
      uid,
      token,
      new_password1: newPassword1,
      new_password2: newPassword2,
    });

    if (res) {
      this.passwordConfirmationMsg = res.data.detail;
    }
  };

  confirmRecoveryPassword = async (resetToken, newPassword1, newPassword2) => {
    const res = await this.rootStore.makeRequest(
      'post',
      '/user/password/recovery/confirm/',
      {
        key: resetToken,
        new_password1: newPassword1,
        new_password2: newPassword2,
      }
    );

    if (res) {
      this.passwordConfirmationMsg = res.data.detail;
    }
  };

  isPrimaryShift = async (answer) => {
    this.setFirstLaunch(false);
    const isPrimarySecurity =
      this.rootStore.callsStats.primarySecurity.id === this.securityId;
    if (answer && isPrimarySecurity) return;

    const data = { set_primary_security: answer };
    this.isLoading = true;
    await this.rootStore.makeRequest(
      'post',
      'user/set_primary_security/',
      data
    );
  };

  setSecurityShift = () => {
    this.rootStore.callsStats.getCallsStats();
    this.setFirstLaunch(false);
    this.isLoading = false;
    this.isUserDecision = false;
  };

  changeLang = (language) => {
    this.lang = language;
    localStorage.setItem('lang', language);
    let langToServer = null;
    if (language === 'en') langToServer = 'English';
    if (language === 'fr') langToServer = 'Français';
    if (language === 'es') langToServer = 'Española';
    this.rootStore.makeRequest('post', 'user/language/', {
      language: langToServer,
    });
  };

  setIsSecurity = (role) => {
    this.isSecurity = role === 'security';
  };

  setFirstLaunch = (value) => {
    this.isFirstLaunch = value;
  };
}

export default decorate(AuthStore, {
  lang: observable,
  changeLang: action,
  isLoading: observable,
  authUser: observable,
  email: observable,
  firstName: observable,
  lastName: observable,
  isSecurity: observable,
  lastLogin: observable,
  registrationDate: observable,
  passwordConfirmationMsg: observable,
  securityId: observable,
  isPrimarySecurity: observable,
  isFirstLaunch: observable,
  setFirstLaunch: action,
  resetSecurityData: action,
  isOpenPopupPrimarySecurity: observable,
  isUserDecision: observable,
  getInitData: action,
  logOutUser: action,
  getUserInfo: action,
  getUserInfoTemp: action,
  changePassword: action,
  confirmPassword: action,
  confirmRecoveryPassword: action,
  recoveryPasswordRequest: action,
  isPrimaryShift: action,
  setSecurityShift: action,
  setIsSecurity: action,
  userFullName: computed,
  profileInfo: computed,
  profileActivityInfo: computed,
});
