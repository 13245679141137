import React from 'react';
import Modal from '../Modal';
import { useRootStore } from '../../store/RootStateContext';

export default function SocketsErrorModal() {
  const { errorsStore } = useRootStore();
  const handleClose = () => {
    errorsStore.hideSocketError();
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Modal
      title="Failed to establish connection"
      onCancel={handleClose}
      cancelBtnTxt="Ok"
      onOk={handleReload}
      okBtnTxt="Refresh the page"
    >
      Please check your internet connection and try again later. If the issue
      persists, contact your system administrator for assistance.
    </Modal>
  );
}
