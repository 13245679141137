import LOCALES from '../locales';

export default {
  [LOCALES.SPANISH]: {
    'menu.profile': 'Perfil',
    'menu.setting': 'Ajuste',
    'menu.logout': 'Cierre de sesión',
    'menu.dashboard': 'Panel de control',

    'statusSecutiry.primary': 'Seguridad primaria:',

    'callPlayer.openDoor': 'Puerta abierta',
    'callPlayer.connectionLost': '¡Se ha perdido la conexión!',
    'callPlayer.reconnecting': 'Reconectando...',
    'callPlayer.answerCall': 'Responder a la llamada',
    'callPlayer.denyAccess': 'Denegar el acceso',
    'callPlayer.endCall': 'End Call',

    'callSettingsInfo.redirected': 'Redirigido a:',
    'callSettingsInfo.rollover': 'Relevado a:',

    'confirmForm.msgEmptyPass': '¡Por favor, introduzca su contraseña!',
    'confirmForm.newPassword': 'Nueva contraseña',
    'confirmForm.msgEmptyPassConfirm': '¡Por favor, confirme su contraseña!',
    'confirmForm.repeatNewPassword': 'Repita la nueva contraseña',
    'confirmForm.confirmPassword': 'Confirme la contraseña',
    'confirmForm.confirmScreen': 'Confirmation screen',

    'controlTableCall.msgModal':
      '¿Está seguro de que desea iniciar una nueva sesión? ¡La sesión actual se cerrará!',
    'controlTableCall.warning': 'Advertencia',
    'controlTableCall.connect': 'Conectar',
    'controlTableCall.cancel': 'Cancelar',

    'controlTableConfig.good': 'bueno',
    'controlTableConfig.open': 'abierto',
    'controlTableConfig.closed': 'cerrado',
    'controlTableConfig.locked': 'bloqueado',
    'controlTableConfig.unlocked': 'desbloqueado',
    'controlTableConfig.lockdown': 'clausurado',
    'controlTableConfig.disabled': 'desactivado',
    'controlTableConfig.NA': 'ND',
    'controlTableConfig.connected': 'conectado',
    'controlTableConfig.notConnected': 'no conectado',
    'controlTableConfig.activated': 'activated',

    'timerBtn.labelTimeDuration': 'Seleccione la duración (en minutos):',
    'timerBtn.labelCustomDuration':
      'O introduzca una duración personalizada (en minutos, máximo 480 min)',
    'timerBtn.selectOrEnterTime': 'Seleccione o introduzca el tiempo',
    'timerBtn.submit': 'Enviar',
    'timerBtn.cancel': 'Cancelar',

    'controlTableSettings.visitorUnit': 'UNIDAD DE VISITANTES',
    'controlTableSettings.lock': 'BLOQUEAR',
    'controlTableSettings.door': 'PUERTA',
    'controlTableSettings.doorControllerStatus':
      'ESTADO DEL CONTROLADOR DE LA PUERTA',
    'controlTableSettings.networkStatus': 'ESTADO DE LA RED',
    'controlTableSettings.videoConnect': 'VÍDEO CONECTADO',
    'controlTableSettings.openDoor': 'PUERTA ABIERTA',
    'controlTableSettings.unlock': 'DESBLOQUEAR',
    'controlTableSettings.timer': 'TEMPORIZADOR',
    'controlTableSettings.disableVisiror': 'DESACTIVAR VISITANTE',
    'controlTableSettings.lockdown': 'CLAUSURADO',

    'headerNavMenu.dashboard': 'Panel de control',

    'incomingCallNotification.incomingCallFrom':
      'Tiene una llamada entrante de',
    'incomingCallNotification.answer': 'Contestar',
    'incomingCallNotification.deny': 'Denegar',
    'incomingCallNotification.incomingCall': 'Llamada entrante',

    'logsSection.realTimeLogging': 'Registro en tiempo real',
    'logsSection.acknowledgeAll': 'Confirmar todo',
    'logsSection.noData': 'Sin datos',

    'notificationDrawer.acknowledgeAll': 'Confirmar todo',
    'notificationDrawer.noNewNotifications': 'No hay nuevas notificaciones',
    'notificationDrawer.visitorUnit': 'The Visitor Unit {name}',
    'notificationDrawer.gotIt': 'Recibido',
    'notificationDrawer.snooze': 'Snooze ',
    'notificationDrawer.notifications': 'Notificaciones',

    'passRecoveryForm.msgEmptyEmail':
      'Por favor, introduzca su correo electrónico',
    'passRecoveryForm.email': 'Correo electrónico',
    'passRecoveryForm.recoveryScreen': 'Forgot password',
    'passRecoveryForm.sendRequest': 'Enviar solicitud',
    'passRecoveryForm.confimationRequestSent':
      '¡Solicitud de confirmación enviada!',

    'profileSection.oldPassword': 'Contraseña antigua',
    'profileSection.newPassword': 'Nueva contraseña',
    'profileSection.msgEmptyPass': '¡Por favor, introduzca su contraseña!',
    'profileSection.newPassConfirmation': 'Confirmación de la nueva contraseña',
    'profileSection.msgEmptyConfirmPass':
      '¡Por favor, confirme su contraseña! ',
    'profileSection.profile': 'Perfil ',
    'profileSection.changePass': 'Cambiar contraseña',

    'settingsSection.settings': 'Ajustes',
    'settingsSection.primary': 'Seguridad primaria',
    'settingsSection.redirectionFromVU':
      'Redirección de llamadas desde unidades de visitantes',
    'settingsSection.rolloverFromVU':
      'Relevo de llamadas desde unidades de visitantes',
    'settingsSection.msgNoSelected':
      'Ningún usuario seleccionado para la redirección y el relevo',
    'settingsSection.membersNotFound':
      'Members for redirect/rollover are not found',
    'settingsSection.apply': 'Aplicar',
    'settingsSection.noteDescription':
      'Nota: Los ajustes de la última persona seleccionada como seguridad principal que inició sesión y esta persona, ambas recibirán todas las llamadas de redirección y relevo. Para renovar los ajustes, por favor inicie sesión de nuevo.',
    'settingsSection.directCallsVUList':
      'Listado de llamadas directas de unidades de visitantes',
    'settingsSection.VUname': 'Nombre de la UV',
    'settingsSection.username': 'Nombre del usuario',

    'signInForm.logInScreen': 'Pantalla de inicio de sesión',
    'signInForm.msgEmptyEmail': '¡Por favor, introduzca su correo electrónico!',
    'signInForm.email': 'Correo electrónico',
    'signInForm.msgEmptyPass': '¡Por favor, introduzca su contraseña!',
    'signInForm.pass': 'Contraseña',
    'signInForm.forgotPass': 'Olvidé mi contraseña',
    'signInForm.login': 'Iniciar sesión',

    'errorPage.titleResult': 'Han surgido algunos problemas con su operación',

    'services.settingsChanges': 'Cambios de ajustes',
    'services.settingsAppliedSuccessfully': 'Ajustes aplicados con éxito.',
    'services.profileChanges': 'Cambios en el perfil',
    'services.passChangedSuccessfully': 'Password changed successfully.',
    'services.passChangeError': 'Error en el cambio de contraseña',
    'services.passRecoveryError': 'Error en la recuperación de la contraseña',
    'services.settingsChangeError': 'Error en el cambio de los ajustes',
    'services.authorizationError': 'Error de autorización',
    'services.msgIncorrectEmail':
      'Por favor, introduzca la dirección de correo electrónico y la contraseña correctas para una cuenta de seguridad. Tenga en cuenta que ambos campos distinguen entre mayúsculas y minúsculas.',
    'services.confirmationError': 'Error de confirmación',
    'services.unknownError': 'Error desconocido',
    'services.somethingWentWrong': '¡algo ha salido mal! (Code: {status})',
    'services.msgErrorMatchPass':
      '¡Las dos contraseñas que ha introducido no coinciden!',
    'services.msgErrorPassValidation':
      'La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula, 1 carácter especial, 1 número',
    'services.wrongEmail': '¡Correo electrónico incorrecto!',

    'auth.email': 'Correo electrónico',
    'auth.firstName': 'Nombre',
    'auth.lastName': 'Apellido',
    'auth.registrationDate': 'Fecha de registro',
    'auth.lastLoginDate': 'Última fecha de inicio de sesión',

    'modal.isItYourShift': '¿Es su turno?',
    'modal.primaryIsChanged': 'La seguridad primaria ha cambiado',
    'modal.primaryTurnedOff': 'Primary Security status has been turned off',
    'modal.withNameReturnPrimary':
      '{name} es ahora seguridad primaria. ¿Desea restituir el estado de seguridad primaria?',
    'modal.returnPrimary': 'Do you want to return Primary Security status?',
    'modal.msgShiftModal':
      'Si es su turno, le habilitaremos como Seguridad Principal y se aplicarán sus Ajustes.',
    'modal.yes': 'Sí',
    'modal.no': 'No',
  },
};
