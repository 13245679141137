import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { LoadingOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import PrimarySecurityShiftPopup from '../PrimarySecurityShiftPopup';
import { useRootStore } from '../../store/RootStateContext';
import Spinner from '../Spinner';
import Switcher from '../Switcher';
import SelectMenu from '../SelectMenu';

import './styles.less';

const SettingsSection = () => {
  const { settingsStore, authStore, callsStats } = useRootStore();
  const intl = useIntl();

  const isPrimarySecurity =
    authStore.securityId === callsStats.primarySecurity.id;

  useEffect(() => {
    settingsStore.getData();
  }, [settingsStore]);

  const isUsersForSelect = !!settingsStore.filteredTenantsList.length;

  const onApply = () => {
    if (settingsStore.selectedTenantId) {
      settingsStore.applyChanges();
    }
  };

  const closePopupPrimerySecurityChanged = () => {
    authStore.isOpenPopupPrimarySecurity = false;
  };

  const setTenant = (value) => {
    settingsStore.selectedTenantId = value;
    onApply();
  };

  const onRedirection = (checked) => {
    if (checked && settingsStore.isRollover) {
      settingsStore.isRollover = false;
    }

    settingsStore.isRedirection = checked;
    onApply();
  };

  const onRollover = (checked) => {
    if (checked && settingsStore.isRedirection) {
      settingsStore.isRedirection = false;
    }
    settingsStore.isRollover = checked;
    onApply();
  };

  const onPrimarySecurity = (checked) => {
    authStore.isUserDecision = true;
    authStore.isPrimaryShift(checked);
  };

  return settingsStore.isSettingsLoading ? (
    <div className="settings-spinner-wrapper">
      <Spinner />
    </div>
  ) : (
    <>
      {authStore.isOpenPopupPrimarySecurity && (
        <PrimarySecurityShiftPopup
          title={
            callsStats.primarySecurity.name ? (
              <FormattedMessage id="modal.primaryIsChanged" />
            ) : (
              <FormattedMessage id="modal.primaryTurnedOff" />
            )
          }
          body={
            callsStats.primarySecurity.name ? (
              <FormattedMessage
                id="modal.withNameReturnPrimary"
                values={{ name: callsStats.primarySecurity.name }}
              />
            ) : (
              <FormattedMessage id="modal.returnPrimary" />
            )
          }
          onDecline={closePopupPrimerySecurityChanged}
        />
      )}

      <div className="section-wrapper">
        <div className="header">
          <FormattedMessage id="settingsSection.settings" />
        </div>
        <div className="settings-switch-section">
          <div className="settings-wrapper">
            <Switcher
              className="switch-btn"
              isChecked={isPrimarySecurity}
              onChange={(checked) => onPrimarySecurity(checked)}
              isDisabled={authStore.isLoading}
            >
              <span>
                <FormattedMessage id="settingsSection.primary" />
              </span>
              {authStore.isLoading && (
                <LoadingOutlined spin className="loadingPadding" />
              )}
            </Switcher>

            <Switcher
              className="switch-btn"
              isChecked={settingsStore.isRedirection}
              onChange={(checked) => onRedirection(checked)}
              isDisabled={!isPrimarySecurity || !isUsersForSelect}
            >
              <span>
                <FormattedMessage id="settingsSection.redirectionFromVU" />
              </span>
            </Switcher>

            <Switcher
              className="switch-btn"
              isChecked={settingsStore.isRollover}
              onChange={(checked) => onRollover(checked)}
              isDisabled={!isPrimarySecurity || !isUsersForSelect}
            >
              <span>
                <FormattedMessage id="settingsSection.rolloverFromVU" />
              </span>
            </Switcher>

            <SelectMenu
              list={settingsStore.filteredTenantsList}
              selectedItem={settingsStore.selectedTenantId}
              onChange={setTenant}
              placeholder={intl.formatMessage({
                id: settingsStore.filteredTenantsList.length
                  ? 'settingsSection.msgNoSelected'
                  : 'settingsSection.membersNotFound',
              })}
              isDisabled={
                !isPrimarySecurity ||
                (!settingsStore.isRedirection && !settingsStore.isRollover)
              }
              isOpen={isUsersForSelect && !settingsStore.selectedTenantId}
            />

            {settingsStore.isSettingChanging && (
              <div className="spinner-div">
                <Spinner />
              </div>
            )}

            <div className="note-description">
              <FormattedMessage id="settingsSection.noteDescription" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(SettingsSection);
