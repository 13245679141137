import { observable, decorate, action } from 'mobx';

class Companies {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  companies = [];

  currentCompanyId = null;

  isCompaniesLoading = true;

  isCompanyLoading = false;

  subscribe = () => {
    this.rootStore.socket.subscribe('company_list', ({ data }) =>
      this.updateCompanyList(data)
    );
    this.rootStore.socket.subscribe('successful_save', ({ data }) => {
      this.setIsCompanyLoading(false);
      this.refreshData(data);
    });
  };

  setIsAllCompaniesLoading = (value) => {
    this.isCompaniesLoading = value;
  };

  setIsCompanyLoading = (value) => {
    this.isCompanyLoading = value;
  };

  refreshData = ({ company_admin_role }) => {
    this.setIsAllCompaniesLoading(false);
    this.rootStore.authStore.setIsSecurity(company_admin_role);

    this.rootStore.socket.send({ activity: 'action_get_devices' });
    this.rootStore.socket.send({ activity: 'action_calls_stats' });
    this.rootStore.socket.send({ activity: 'action_get_notifications' });
  };

  updateCompanyList = (data) => {
    this.companies = data.company_list;
    this.currentCompanyId = data.selected_company;
  };

  setNewCompany = (companyId) => {
    this.setIsCompanyLoading(true);
    this.rootStore.socket.send({
      activity: 'action_selected_company',
      company_id: companyId,
    });
    this.currentCompanyId = companyId;
  };

  getAllCompanies = () => {
    this.setIsAllCompaniesLoading(true);
    this.rootStore.socket.send({ activity: 'action_company_list' });
  };
}

export default decorate(Companies, {
  companies: observable,
  currentCompanyId: observable,
  isCompaniesLoading: observable,
  isCompanyLoading: observable,
  updateCompanyList: action,
  subscribe: action,
  setNewCompany: action,
  getAllCompanies: action,
  setIsAllCompaniesLoading: action,
});
