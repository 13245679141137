import React from 'react';
import { notification } from 'antd';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { useRootStore } from '../../store/RootStateContext';
import useAutorunEffect from '../../services/useAutorunEffect';

const IncomingCallNotification = ({ el }) => {
  const { tokBoxStore } = useRootStore();
  const notKey = el.data.call_id;

  const onAnswerNotificationCall = () => {
    tokBoxStore.acceptNotificationCall(el);
  };

  const onDenyNotificationCall = () => {
    tokBoxStore.denyNotificationCall(el);
  };

  const content = (
    <div className="notification-call-msg-wrapper">
      <span>
        <FormattedMessage id="incomingCallNotification.incomingCallFrom" />
        <span className="notification-call-device-name">
          {el.data.device_name}
        </span>
      </span>
      <div className="notifications-call-controls-wrapper">
        <button
          type="button"
          className="notifications-call-btn"
          onClick={onAnswerNotificationCall}
        >
          <FormattedMessage id="incomingCallNotification.answer" />
        </button>
        <button
          type="button"
          className="notifications-call-btn"
          onClick={onDenyNotificationCall}
        >
          <FormattedMessage id="incomingCallNotification.deny" />
        </button>
      </div>
    </div>
  );

  useAutorunEffect(() => {
    notification.info({
      key: notKey,
      duration: 0,
      message: <FormattedMessage id="incomingCallNotification.incomingCall" />,
      description: content,
    });
    tokBoxStore.unansweredNotificationCallCountdown(el);
  }, [el, tokBoxStore.unansweredNotificationCallCountdown]);

  return null;
};

export default observer(IncomingCallNotification);
