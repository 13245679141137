import LOCALES from '../locales';

export default {
  [LOCALES.ENGLISH]: {
    'menu.profile': 'Profile',
    'menu.setting': 'Setting',
    'menu.logout': 'Logout',
    'menu.dashboard': 'Dashboard',

    'statusSecutiry.primary': 'Primary Security :',

    'callPlayer.openDoor': 'Open Door',
    'callPlayer.connectionLost': 'Connection lost! Reconnecting...',
    'callPlayer.reconnecting': 'Reconnecting...',
    'callPlayer.answerCall': 'Answer Call ',
    'callPlayer.denyAccess': 'Deny Access',
    'callPlayer.endCall': 'End Call',

    'callSettingsInfo.redirected': 'Redirected to: ',
    'callSettingsInfo.rollover': 'Rollover to: ',

    'confirmForm.msgEmptyPass': 'Please input your Password!',
    'confirmForm.newPassword': 'New Password',
    'confirmForm.msgEmptyPassConfirm': 'Please confirm your password!',
    'confirmForm.repeatNewPassword': 'Repeat New Password',
    'confirmForm.confirmPassword': 'Confirm Password',
    'confirmForm.confirmScreen': 'Confirmation screen',

    'controlTableCall.msgModal':
      'Are you sure you want to start new session? Current session will be closed!',
    'controlTableCall.warning': 'Warning!',
    'controlTableCall.connect': 'Connect',
    'controlTableCall.cancel': 'Cancel',

    'controlTableConfig.good': 'good',
    'controlTableConfig.open': 'open',
    'controlTableConfig.closed': 'closed',
    'controlTableConfig.locked': 'locked',
    'controlTableConfig.unlocked': 'unlocked',
    'controlTableConfig.lockdown': 'lockdown',
    'controlTableConfig.disabled': 'disabled',
    'controlTableConfig.NA': 'NA',
    'controlTableConfig.connected': 'connected',
    'controlTableConfig.notConnected': 'not connected',
    'controlTableConfig.activated': 'activated',

    'timerBtn.labelTimeDuration': 'Choose time duration(in minutes): ',
    'timerBtn.labelCustomDuration':
      'Or enter custom duration(in minutes, max 480 min)',
    'timerBtn.selectOrEnterTime': 'Select or enter time',
    'timerBtn.submit': 'Submit',
    'timerBtn.cancel': 'Cancel',

    'controlTableSettings.visitorUnit': 'VISITOR UNIT',
    'controlTableSettings.lock': 'LOCK',
    'controlTableSettings.door': 'DOOR',
    'controlTableSettings.doorControllerStatus': 'DOOR CONTROLLER STATUS',
    'controlTableSettings.networkStatus': 'NETWORK STATUS',
    'controlTableSettings.videoConnect': 'VIDEO CONNECT',
    'controlTableSettings.openDoor': 'OPEN DOOR',
    'controlTableSettings.unlock': 'UNLOCK',
    'controlTableSettings.timer': 'TIMER',
    'controlTableSettings.disableVisiror': 'DISABLE VISITOR',
    'controlTableSettings.lockdown': 'LOCKDOWN',

    'headerNavMenu.dashboard': 'Dashboard',

    'incomingCallNotification.incomingCallFrom': 'You have incoming call from',
    'incomingCallNotification.answer': 'Answer',
    'incomingCallNotification.deny': 'Deny',
    'incomingCallNotification.incomingCall': 'Incoming Call',

    'logsSection.realTimeLogging': 'Real time logging',
    'logsSection.acknowledgeAll': 'Acknowledge All',
    'logsSection.noData': 'No data',

    'notificationDrawer.acknowledgeAll': 'Acknowledge All',
    'notificationDrawer.noNewNotifications': 'No new notifications',
    'notificationDrawer.visitorUnit': 'The Visitor Unit {name}',
    'notificationDrawer.gotIt': 'Got it',
    'notificationDrawer.snooze': 'Snooze ',
    'notificationDrawer.notifications': 'Notifications',

    'passRecoveryForm.msgEmptyEmail': 'Please input your E-mail!',
    'passRecoveryForm.email': 'E-mail',
    'passRecoveryForm.recoveryScreen': 'Forgot password',
    'passRecoveryForm.sendRequest': 'Submit',
    'passRecoveryForm.confimationRequestSent': 'Confirmation request sent!',

    'profileSection.oldPassword': 'Old Password',
    'profileSection.newPassword': 'New Password',
    'profileSection.msgEmptyPass': 'Please input your Password!',
    'profileSection.newPassConfirmation': 'New Password Confirmation',
    'profileSection.msgEmptyConfirmPass': 'Please confirm your password! ',
    'profileSection.profile': 'Profile ',
    'profileSection.changePass': 'Change password',

    'settingsSection.settings': 'Settings',
    'settingsSection.primary': 'Primary Security',
    'settingsSection.redirectionFromVU':
      'Redirection of calls from visitor units',
    'settingsSection.rolloverFromVU': 'Rollover of calls from visitor units',
    'settingsSection.msgNoSelected':
      'No selected user for redirection and rollover',
    'settingsSection.apply': 'Apply',
    'settingsSection.membersNotFound':
      'Members for redirect/rollover are not found',
    'settingsSection.noteDescription':
      'Note: Settings of the last logged Security selected as the primary and this person will get all Redirected or Rollover calls in order to renew settings, please log in again.',
    'settingsSection.directCallsVUList': 'Direct Calls VU List',
    'settingsSection.VUname': 'VU name',
    'settingsSection.username': 'User name',

    'signInForm.logInScreen': 'Login',
    'signInForm.msgEmptyEmail': 'Please input your E-mail!',
    'signInForm.email': 'E-mail',
    'signInForm.msgEmptyPass': 'Please input your Password!',
    'signInForm.pass': 'Password',
    'signInForm.forgotPass': 'Forgot password',
    'signInForm.login': 'Log in',

    'errorPage.titleResult': '!!!There are some problems with your operation',

    'services.settingsChanges': 'Settings changes',
    'services.settingsAppliedSuccessfully': 'Settings applied successfully.',
    'services.profileChanges': 'Profile changes',
    'services.passChangedSuccessfully': 'Password changed successfully.',
    'services.passChangeError': 'Password change error',
    'services.passRecoveryError': 'Password recovery error',
    'services.settingsChangeError': 'Settings change error',
    'services.authorizationError': 'Authorization error',
    'services.msgIncorrectEmail':
      'Please enter the correct email address and password for a security account. Note that both fields may be case-sensitive.',
    'services.confirmationError': 'Confirmation error',
    'services.unknownError': 'Unknown error',
    'services.somethingWentWrong': 'something went wrong! (Code: {status})',
    'services.msgErrorMatchPass':
      'The two passwords that you entered do not match!',
    'services.msgErrorPassValidation':
      'Password should contain at least 1 upper case letter, 1 lower case letter, 1 special character, 1 number',
    'services.wrongEmail': 'Wrong email!',

    'auth.email': 'E-mail',
    'auth.firstName': 'First Name',
    'auth.lastName': 'Last Name',
    'auth.registrationDate': 'Registration Date',
    'auth.lastLoginDate': 'Last Login Date',

    'modal.isItYourShift': 'Is it your shift?',
    'modal.primaryIsChanged': 'Primary Security is changed',
    'modal.primaryTurnedOff': 'Primary Security status has been turned off',
    'modal.withNameReturnPrimary':
      '{name} is Primary Security now. Do you want to return Primary Security status?',
    'modal.returnPrimary': 'Do you want to return Primary Security status?',
    'modal.msgShiftModal':
      "If it is your shift we'll enable you as a Primary Security and your Settings will be applied.",
    'modal.yes': 'Yes',
    'modal.no': 'No',
  },
};
