import React from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import { LOCALES } from '../../i18n';
import { useRootStore } from '../../store/RootStateContext';

const { Option } = Select;

const LangSwitch = () => {
  const { authStore } = useRootStore();

  const handleChange = (lang) => {
    authStore.changeLang(lang);
  };

  const renderLocale = (locales) => {
    const localesAbv = Object.values(locales).sort();
    return localesAbv.map((locale) => (
      <Option key={locale} value={locale}>
        {locale.toUpperCase()}
      </Option>
    ));
  };

  return (
    <Select
      value={authStore.lang}
      style={{ width: 80 }}
      onChange={handleChange}
    >
      {renderLocale(LOCALES)}
    </Select>
  );
};

export default observer(LangSwitch);
