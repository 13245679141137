import { createIntl } from 'react-intl';
import { LOCALES } from './index';
import messages from './messages/index';

const getLocale = () => {
  return localStorage.lang || LOCALES.ENGLISH;
};

const getIntl = () => {
  return createIntl({
    locale: getLocale(),
    messages: messages[getLocale()],
  });
};

export default getIntl;
