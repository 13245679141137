import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleOutlined } from '@ant-design/icons';
import * as moment from 'moment';
import { LOCALES } from '../../i18n/index';
import 'moment/locale/es';
import 'moment/locale/fr';

import './styles.less';

const LogItem = ({ item, isLog, isNotification, toggleModal }) => {
  moment.locale(localStorage.getItem('lang') || LOCALES.ENGLISH);
  const time = moment.unix(item.timestamp).utc(0).format('dddd, LT');
  return (
    <div
      className={`item-wrapper ${isNotification ? 'notification-item' : ''}`}
      onClick={isNotification ? toggleModal : null}
    >
      <div className="data-wrapper">
        <div className="log-date">{time}</div>
        <div className="log-content">
          <span className="log-device-name">{item.data.device_name} </span>
          {item.data.details}
        </div>
      </div>
      {isLog && <CheckCircleOutlined className="status-icon positive-check" />}
    </div>
  );
};

LogItem.defaultProps = {
  isLog: false,
  isNotification: false,
  toggleModal: null,
};

LogItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  isLog: PropTypes.bool,
  isNotification: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default LogItem;
